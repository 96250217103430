<template>
  <a-modal
    id="label-video-shortcut-modal"
    v-model:visible="showShortCuts"
    title="Shortcuts"
    destroy-on-close
    :footer="null"
  >
    <!-- <template #body> -->
    <a-table :columns="columns" :data-source="shortcuts" :pagination="false">
      <a-table-column title="Shortcut" dataIndex="shortcut" />
      <a-table-column title="Key" dataIndex="key" />
    </a-table>
    <!-- </template> -->
  </a-modal>
  <a-row
    class="mt-3"
    style="height: 10vh; width: 100vw"
    v-if="!loading"
    :gutter="28"
  >
    <a-col :span="24" class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <h5>Label Video</h5>
        <i
          @click="toggleShortcutModal"
          style="cursor: pointer"
          class="mx-1 mb-2 bi bi-info-circle"
        ></i>
      </div>
    </a-col>
  </a-row>

  <a-row
    :gutter="[4, 1]"
    v-if="!loading"
    class="overflow-auto"
    style="height: calc(150vh - 50px)"
  >
    <!-- <a-row gutter="{24}"> -->
    <a-col
      :span="6"
      class="d-flex flex-column"
      style="height: calc(92vh - 90px)"
    >
      <div class="flex-grow-1" style="overflow: hidden">
        <a-list
          size="small"
          bordered
          :data-source="saveFrames"
          v-if="saveFrames.length"
          class="mt-1 save-frames-list"
          id="save-frames-list"
          tabindex="0"
        >
          <template #header>
            <a-typography-text strong class="frames-header">
              Saved Frames ({{ saveFrames.length }})
            </a-typography-text>
          </template>
          <template #renderItem="{ item, index }">
            <a-list-item
              :id="'save-frame-li' + index"
              class="d-flex justify-content-between"
              :class="{
                'selected-frame': selectedFrame === item,
                'hovered-frame':
                  selectedFrame !== item && hoveredFrameIndex === index,
              }"
              tabindex="-1"
              @click="selectFrame(item)"
              @focus="hoveredFrameIndex = index"
              @mouseover="hoveredFrameIndex = index"
              @mouseleave="hoveredFrameIndex = null"
            >
              <span>Frame: {{ item }}</span>
              <template #actions>
                <delete-outlined
                  class="text-danger"
                  @click.stop="removeFrame(item)"
                  :id="'frame-delete-' + index"
                />
              </template>
            </a-list-item>
          </template>
        </a-list>
      </div>

      <div
        class="d-flex justify-content-end align-items-center"
        style="margin-top: auto"
      >
        <a-button
          v-if="!loading && !isVisualize"
          @click="handleAddSaveFrame"
          id="save-frame-btn"
          class="mt-2 w-100"
          style="border-radius: 4px"
        >
          Save Frame
        </a-button>
      </div>
    </a-col>

    <a-col :span="18" class="d-flex flex-column" style="padding: 10px">
      <VideoPlayer v-show="false" ref="videoPlayer" />

      <div class="flex-grow-1 d-flex flex-column" style="margin-bottom: 2px">
        <video
          v-if="video.availableLocally"
          ref="video"
          muted
          class="m-auto"
          style="
            width: 100%;
            height: 81vh;
            object-fit: cover;
            border-radius: 4px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          "
          :src="video?.localURL || video?.fileURL"
          id="annotate-video-local"
        ></video>

        <video
          v-else
          ref="video"
          muted
          class="m-auto"
          style="
            width: 100%;
            height: 65vh;
            object-fit: cover;
            border-radius: 4px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          "
          :src="video?.localURL || video?.fileURL"
          id="annotate-video-local"
        ></video>
      </div>

      <div
        class=""
        id="annotate-video-labelling-box"
        style="
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
          padding: 10px;
        "
      >
        <labelling-tool
          ref="labellingComponent"
          :stepsList="stepsList"
          :prevMetaData="prevMetaData"
          :stepsToVisualize="stepsToVisualize"
          :videoInfo="video"
          :taskId="taskId"
          :taskName="taskName"
          :totalFrames="totalFrames"
          :frame="currentFrame"
          :markFrames="markFrames"
          :saveFrames="saveFrames"
          :selectedFrame="selectedFrame"
          :isVisualize="isVisualize"
          :zoomIn="zoomIn"
          :organization="organization"
          :updateTaskRecord="updateTaskRecord"
          :stepsTranslationMapping="stepsTranslationMapping"
          :stepsToIndexMapping="stepsToIndexMapping"
          :barcodedSteps="barcodedSteps"
          @frameChange="handleFrameChange"
          @onSave="(data) => $emit('onSave', data)"
          @navigateFrames="navigateFrames"
          @selectHoveredFrame="selectFrame"
          @removeFrame="removeFrame"
        ></labelling-tool>
      </div>
    </a-col>
    <!-- </a-row> -->
  </a-row>

  <!-- <a-row
    class="mt-3"
    style="height: 10vh; width: 100vw"
    v-if="!loading"
    :gutter="28"
  >
    <a-col :span="4" class="h-100">
      
    </a-col>
  </a-row> -->
</template>

<script>
import VideoInfo from './VideoInfo.vue';
import VideoPlayer from './Video.vue';
import LabellingTool from './Labellingv2.vue';
import LabellingInstruction from './Instrunction.vue';
import KeyboardKeysInfo from './KeyboardKeysInfo.vue';
import httpClient from 'src/service/httpClient';
import getStepsList from '../../../shared/Helpers/getStepsList';
import getTime from '../../../shared/Helpers/getTime';
import getNumberOfFrames from '../../../shared/Helpers/getNumberOfFrames';
import TaskRecordService from 'src/services/taskRecord';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import { roles } from 'src/config/roles-config';
import { prodHostUrl } from '../../../../service/http/httpRequestConfig';
import { DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import getTranslatedSteps from '../../../shared/Helpers/getTranslatedSteps.js';
import { getTaskNameAndProcess, getStepToIndexMapping } from '@/utils/task.js';
import { evaluateExpression } from 'src/components/shared/Helpers/expressionHelper';

export default {
  props: {
    video: {},
    taskId: {},
    isVisualize: { default: false },
    prevMetaData: {},
    loading: {},
    organization: {},
    traceframes: [],
    updateTaskRecord: Function,
  },
  inject: ['toast'],
  emits: ['onSave', 'stopLoading', 'updateLoading', 'closeModal'],
  components: {
    VideoInfo,
    VideoPlayer,
    LabellingTool,
    LabellingInstruction,
    KeyboardKeysInfo,
    DeleteOutlined,
    CloseCircleOutlined,
  },
  data: function () {
    return {
      shortcuts: [
        'Shortcut',
        'Key',
        // Add more shortcuts as needed
      ],
      showShortCuts: false,
      taskName: '',
      stepsList: [],
      stepsToVisualize: {},
      currentFrame: 0,
      totalFrames: Number.MAX_SAFE_INTEGER,
      videoMetaData: null,
      zoomIn: false,
      peerConnection: null,
      connection: null,
      value: 0,
      stunCheckbox: true,
      offer: null,
      config: [{ sdpSemantics: 'unified-plan' }],
      rtcInteval: null,
      saveFrames: [],
      markFrames: {},
      stepsTranslationMapping: {},
      predsToStepsMap: {},
      stepsToIndexMapping: {},
      barcodedSteps: [],
      selectedFrame: null,
      hoveredFrameIndex: 0,
      framesAdded: 0,
      shortcuts: [
        { shortcut: 'Previous frame', key: 'a' },
        { shortcut: 'Next frame', key: 'd' },
        { shortcut: 'Fast next frame', key: 'shift + d' },
        { shortcut: 'Fast previous frame', key: 'shift + a' },
        { shortcut: 'Save frame', key: 'f' },
        { shortcut: 'delete frame', key: 'delete' },
        { shortcut: 'navigate up on saved frames', key: 'arrow up' },
        { shortcut: 'navigate down on saved frames', key: 'arrow down' },
        { shortcut: 'select frame', key: 'enter' },
        // Add more shortcuts as needed
      ],
      prevCurrectFrame: null,
    };
  },
  computed: {
    ...mapGetters([
      'role',
      'webSocketConnection',
      'taskDetails',
      'taskStepsCount',
      'translationDetails',
      'translationLanguage',
      'selectedLang',
      'associateBarcodes',
    ]),
  },

  created() {
    window.onbeforeunload = () => '';
  },

  mounted() {
    if (!this.taskDetails) return;
    if (this.traceframes) this.frameUpdate();
    this.setTaskDetails(this.taskDetails, true);
    this.setStepTranslations(this.selectedLang);
    this.setisFrameUpdated(false);
    this.getBarcodedSteps();
    // if (roles.labeler === this.role) this.setupWebRTC();
    document.addEventListener('keyup', this.handleKeyUpEvents);
    document.addEventListener('keydown', this.handleKeyDownEvents);
    // window.addEventListener('beforeunload', this.handleSaveCaptureFrame);
    // window.addEventListener('unload', this.handleSaveCaptureFrame);
  },

  // beforeUnmount() {
  //   this.$emit('updateLoading', true);
  //   document.removeEventListener('keyup', this.handleKeyUpEvents);
  //   document.removeEventListener('keydown', this.handleKeyDownEvents);
  //   window.removeEventListener('beforeunload', this.handleSaveCaptureFrame);
  //   window.removeEventListener('unload', this.handleSaveCaptureFrame);
  //   window.onbeforeunload = null;
  //   this.handleSaveCaptureFrame();
  // },

  watch: {
    selectedLang(value) {
      this.setStepTranslations(value);
    },
  },

  methods: {
    ...mapActions(['setSelectedLanguage', 'setisFrameUpdated']),

    toggleShortcutModal() {
      this.showShortCuts = !this.showShortCuts;
    },

    handleKeyDownEvents(event) {
      if (event.key?.toLowerCase() === 'f') this.saveCurrentFrame();
    },

    handleKeyUpEvents(event) {
      if (event.key === 'Escape') {
        this.$emit('closeModal');
      }
    },

    setStepTranslations(lang) {
      if (lang === this.translationLanguage) {
        this.stepsTranslationMapping = getTranslatedSteps(
          this.taskDetails,
          this.translationDetails
        );
      } else {
        this.stepsTranslationMapping = getTranslatedSteps(this.taskDetails);
      }
    },

    setTaskDetails(taskDetail, getData) {
      const temp = getStepsList(taskDetail);
      this.taskName = temp?.name || '';
      this.stepsList = temp?.steps || [];
      const { processes } = getTaskNameAndProcess(taskDetail);
      const stepToIndexMap = getStepToIndexMapping(processes);
      this.stepsToIndexMapping = stepToIndexMap;
      getData && this.getVideoMetaData();
    },

    getBarcodedSteps() {
      this.barcodedSteps = this.associateBarcodes
        ?.map((barcode) => barcode.step_index)
        .sort();
    },

    handleAddSaveFrame() {
      const { currentFrame } = this;
      const frameObject = {
        style: {
          color: '#40a9ff',
        },
        label: `${currentFrame}`,
      };
      if (!this.saveFrames.includes(currentFrame)) {
        this.saveFrames = [...this.saveFrames, currentFrame];
        this.markFrames[currentFrame] = frameObject;
        this.framesAdded += 1;
        this.setisFrameUpdated(true);
        // if(this.traceframes)
        // this.traceframes.push(currentFrame)
      }
    },

    saveCurrentFrame() {
      if (this.loading || this.isVisualize) return;
      this.handleAddSaveFrame();
    },

    async handleSaveCaptureFrame() {
      if (!this.saveFrames.length) return;
      const payload = {
        task_id: this.taskId,
        frames: this.saveFrames,
        file_name: this.video.fileName,
        file_url: this.video.fileURL,
      };
      await httpClient.post(
        'generic/task/save_annotation_images/',
        payload,
        false,
        false,
        false,
        '',
        ''
      );
      this.saveFrames = [];
    },

    removeFrame(frame = null) {
      if (frame) {
        this.saveFrames = this.saveFrames.filter((f) => f !== frame);
        delete this.markFrames[frame];
      } else {
        const frame_to_delete = this.saveFrames[this.hoveredFrameIndex];
        this.saveFrames = this.saveFrames.filter((f) => f !== frame_to_delete);
        delete this.markFrames[frame_to_delete];
      }
      this.framesAdded -= 1;
      this.setisFrameUpdated(true);
    },

    uploadCurrentFrameToS3() {
      const { fileName } = this.video;
      this.$refs.videoPlayer.uploadFrameForAnnotationtest1(
        this.taskName,
        fileName,
        this.currentFrame
      );
    },

    handleFrameChange(frame) {
      this.currentFrame = frame;
      const videoFPS = this.videoMetaData?.frameRate || this.video?.fps;
      const frameToMove = (Number(frame) / videoFPS).toFixed(25);
      this.$refs.video.currentTime = frameToMove;
    },

    getPreds(pred) {
      const key = JSON.stringify(pred);
      if (this.predsToStepsMap[key]) return this.predsToStepsMap[key];

      const result = [];

      pred = pred.reduce((res, el) => {
        res[el] = el;
        return res;
      }, {});

      for (let i = 0; i < this.taskStepsCount; i++) result.push(pred[i]);

      this.predsToStepsMap = {
        ...this.predsToStepsMap,
        key: result,
      };
      return result;
    },

    async parseTaskDetails() {
      const stepsDict = {};
      const entries = Object.entries(this.video?.predictions).map(
        ([frame, value]) => {
          const { pred } = value;
          const res = this.getPreds(pred);
          return [frame, { ...value, pred: res }];
        }
      );

      if (entries.length === 0) return;

      let prevValue = entries[0][1]?.pred || entries[0][1],
        prevKey = entries[0][0];

      entries.forEach(([key, value]) => {
        const first = value.pred ? value.pred : value;

        prevValue.forEach((prev, index) => {
          if (!stepsDict[first[index]]) {
            stepsDict[first[index]] = [{ start: key }];
            if (prev !== first[index]) {
              const lastIndex = stepsDict[prev].length - 1;
              const prevEl = stepsDict[prev][lastIndex];
              stepsDict[prev][lastIndex] = { ...prevEl, end: prevKey };
            }
          } else if (prev !== first[index]) {
            stepsDict[first[index]].push({ start: key });
            const lastIndex = stepsDict[prev].length - 1;
            const prevEl = stepsDict[prev][lastIndex];
            stepsDict[prev][lastIndex] = { ...prevEl, end: prevKey };
          }
        });

        prevValue = value?.pred || value;
        prevKey = key;
      });
      const prev = prevValue[0];

      const lastIndex = stepsDict[prev].length - 1;
      const prevEl = stepsDict[prev][lastIndex];
      stepsDict[prev][lastIndex] = { ...prevEl, end: prevKey };

      this.stepsToVisualize = stepsDict;
    },

    async getVideoMetaData() {
      if (!this.video) return;
      const payload = { id: this.video?.id };
      const [error, res] = await TaskRecordService.getVideoMetaData(
        payload,
        false
      );
      if (error) {
        console.log({ error });
        return 30;
      }
      const { data } = res;
      if (!data) {
        this.setDefaultVideoMetaData();
        return;
      }

      this.setVideoMetaData(data);
      if (this.isVisualize) this.parseTaskDetails();
      this.$emit('stopLoading');
    },

    setVideoMetaData(data) {
      let { duration, fps, total_frames } = data;

      this.totalFrames = total_frames;

      this.videoMetaData = {
        fps,
        total_frames,
        duration,
      };
    },

    setDefaultVideoMetaData() {
      if (!this.video) return;
      const { fps, duration } = this.video;
      this.totalFrames = getNumberOfFrames(duration, fps);
    },
    selectFrame(item = null) {
      var _item = item ? item : this.saveFrames[this.hoveredFrameIndex];
      if (this.selectedFrame === _item) {
        this.selectedFrame = null;
        this.handleFrameChange(this.prevCurrectFrame);
        const frameObject = {
          style: {
            color: '#40a9ff',
          },
          label: `${_item}`,
        };
        this.markFrames[_item] = frameObject;
      } else {
        this.selectedFrame = _item;
        this.prevCurrectFrame = this.currentFrame;
        this.handleFrameChange(_item);
        const frameObject = {
          style: {
            color: '#4287f5',
          },
          label: `${_item}`,
        };
        this.markFrames[_item] = frameObject;
      }
    },
    navigateFrames(direction) {
      // Navigate through the frames using arrow keys
      const listLength = this.saveFrames.length;
      this.hoveredFrameIndex =
        (this.hoveredFrameIndex + direction + listLength) % listLength;
    },
    frameUpdate() {
      var data = this.traceframes;
      if (data) {
        const frames = data || [];
        frames.forEach((frame) => {
          const frameObject = {
            style: {
              color: '#40a9ff',
            },
            label: `${frame}`,
          };
          this.markFrames[frame] = frameObject;
          this.saveFrames.push(frame);
        });
      }
    },
  },
};
</script>
<style scoped>
video {
  object-fit: cover;
}

.annotate-top-col {
  /* height: 300px; */
}
.annotate-video-col {
  /* height: 300px; */
  /*  */
}
@media only screen and (max-width: 768px) {
  .annotate-top-col {
    height: 470px;
  }
  .annotate-video-col {
    height: 350px;
  }
}
@media only screen and (max-width: 400px) {
  .annotate-top-col {
    height: 520px;
  }
}
.selected-frame {
  background-color: #e6f7ff;
  border-left: 4px solid #1890ff;
  cursor: pointer;
}

.hovered-frame {
  background-color: #fafafa;
  border-left: 4px solid #ff9900;
  cursor: pointer;
}

.save-frames-list {
  height: 100%; /* Set a fixed height for the list */
  overflow-y: auto; /* Enable vertical scrolling */
}

.flex-grow-1 {
  flex-grow: 1; /* Allow this div to take up remaining space */
}
</style>
